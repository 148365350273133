/* 首页 */
<template>
  <div class="wrapper" style="width: 100%;" >
    <el-row style="margin-top: 20px;" class="statistics f16" :gutter="20">
      <el-col :span="12">
        <div class="bg-white text-center pb-20">
          <div class="left-float-top">
            <div class="statistics-item flex-1">
              <img src="../assets/img/sta-icon01.png" width="60" />
              <div class="t-999">总机构数</div>
              <div><span class="f28 t-cu">206</span>家</div>
            </div>
            <div class="statistics-item flex-1">
              <img src="../assets/img/sta-icon02.png" width="60" />
              <div class="t-999">认证机构数</div>
              <div><span class="f28 t-cu">99</span>家</div>
            </div>
          </div>
          <!-- <a href="#" @click="boardAction"><img src="../assets/img/home-more.png" /></a> -->
        </div>
      </el-col>
      <el-col :span="12">
        <div class="bg-white text-center pb-20">
          <div class="left-float-top">
            <div class="statistics-item flex-1">
              <img src="../assets/img/sta-icon03.png" width="60" />
              <div class="t-999">总填报数(人次)</div>
              <div><span class="f28 t-cu">{{record.fillYear || 659203 }}</span>人</div>
            </div>
            <div class="statistics-item flex-1">
              <img src="../assets/img/sta-icon04.png" width="60" />
              <div class="t-999">当月填报数(人次)</div>
              <div><span class="f28 t-cu">{{record.fillMongth || 33452}}</span>人</div>
            </div>
          </div>
          <!-- <a href="#" @click="boardAction"><img src="../assets/img/home-more.png" /></a> -->
        </div>
      </el-col>
      <!--        <el-col :span="8">-->
      <!--          <div class="bg-white text-center pb-20">-->
      <!--            <div class="left-float-top">-->
      <!--              <div class="statistics-item flex-1">-->
      <!--                <img src="../assets/img/sta-icon05.png" width="60" />-->
      <!--                <div class="t-999">上报国家平台数(人)</div>-->
      <!--                <div><span class="f28 t-cu">308965</span>人</div>-->
      <!--              </div>-->
      <!--              <div class="statistics-item flex-1">-->
      <!--                <img src="../assets/img/sta-icon06.png" width="60" />-->
      <!--                <div class="t-999">当月上报国家平台数(人)</div>-->
      <!--                <div><span class="f28 t-cu">0</span>人</div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            &lt;!&ndash; <a href="#" @click="boardAction"><img src="../assets/img/home-more.png" /></a> &ndash;&gt;-->
      <!--          </div>-->
      <!--        </el-col>-->
    </el-row>
    <div class="home-menu mt-20" style="position: relative">
      <el-menu class="tbc-lr-scatter" :default-active="menuActive" mode="horizontal">
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2"><router-link tag="a" target="_blank" to="/404">认证指导</router-link></el-menu-item>
        <el-menu-item index="3"><a href="https://www.hunancpc.net/cpis-admin/" target="_blank">胸痛专家</a></el-menu-item>
        <el-menu-item index="4"><a href="http://www.yktjsd.com" target="_blank">进修培训</a></el-menu-item>
        <el-menu-item index="5"><router-link tag="a" target="_blank" to="/quality/index">质量控制</router-link></el-menu-item>
        <el-menu-item index="6"><router-link tag="a" target="_blank" to="/404">发展动态</router-link></el-menu-item>
        <el-menu-item index="7"><router-link tag="a" target="_blank" to="/404">社会实践</router-link></el-menu-item>
        <!-- <el-menu-item index="8"><a href="#">数据下载</a></el-menu-item> -->
        <el-menu-item index="9"><router-link tag="a" target="_blank" to="/about/us">联系我们</router-link></el-menu-item>
      </el-menu>

      <div class="fixed-div" v-if="isDisplayMessage">
        <div class="scroll-header t-cu">最近机构审核情况 <i style="float: right;margin-top: 10px;margin-right: 10px;cursor: pointer" class="el-icon-close" @click="isDisplayMessage=false"></i></div>
        <div style="animation: scroll 10s linear infinite;" class="scroll-container">
          <div class="scroll-content">
            <div v-for="(item,index) in message" :key="index" class=" text  scroll-item" >
              <div class="pt-5 pb-5 text-nowrap">
                <span >{{item.profile.name }} </span>   审核<span>{{item.resultName}}</span>    <span>{{ (item.ctime).slice(0,10)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="handle left-float-top mb-30">
      <div class="handle-left f16">
        <div :class="currActive == '1' ? 'handle-left-item center-float-left active' : 'handle-left-item center-float-left'" @click="activeChange('1')" ><img class="mr-10" src="../assets/img/hand-icon01-h.png" />全部</div>
        <div :class="currActive == '2' ? 'handle-left-item center-float-left active' : 'handle-left-item center-float-left'" @click="activeChange('2')" ><img class="mr-10" src="../assets/img/hand-icon02.png" />申报审核</div>
        <div :class="currActive == '3' ? 'handle-left-item center-float-left active' : 'handle-left-item center-float-left'" @click="activeChange('3')" ><img class="mr-10" src="../assets/img/hand-icon03.png" />数据填报</div>
        <div :class="currActive == '4' ? 'handle-left-item center-float-left active' : 'handle-left-item center-float-left'" @click="activeChange('4')" ><img class="mr-10" src="../assets/img/hand-icon04.png" />数据质控</div>
        <div :class="currActive == '5' ? 'handle-left-item center-float-left active' : 'handle-left-item center-float-left'" @click="activeChange('5')" ><img class="mr-10" src="../assets/img/hand-icon05.png" />医院管理</div>
        <div :class="currActive == '6' ? 'handle-left-item center-float-left active' : 'handle-left-item center-float-left'" @click="activeChange('6')" ><img class="mr-10" src="../assets/img/hand-icon06.png" />医师管理</div>
      </div>
      <div class="handle-right flex-1 center-float-left" v-if="!isDisplayVideo">
        <div class="flex-1 left-float-top-br border-right">
          <router-link class="handle-right-item" to="/register/pectoralgia">
            <div class="center-float-left">
              <img src="../assets/img/func-icon01.png" width="42" />
              <div class="pl-20">
                <div class="f24 t-cu pb-5 t-777">申请注册</div>
                <div class="center-float-left t-999"><i class="mr-10 el-icon-office-building"></i>申请注册</div>
              </div>
            </div>
          </router-link>
          <!--          to="/auth/materials"-->
          <router-link class="handle-right-item" to="/authentication">
            <div class="center-float-left">
              <img src="../assets/img/func-icon02.png" width="42" />
              <div class="pl-20">
                <div class="f24 t-cu pb-5 t-777">机构认证</div>
                <div class="center-float-left t-999"><i class="mr-10 el-icon-office-building"></i>机构认证</div>
              </div>
            </div>
          </router-link>
          <div class="handle-right-item" style="cursor: pointer" @click="tbAction()">
            <div class="center-float-left">
              <img src="../assets/img/func-icon03.png" width="42" />
              <div class="pl-20">
                <div class="f24 t-cu pb-5 t-777">数据填报</div>
                <div class="center-float-left t-999"><i class="mr-10 el-icon-office-building"></i>数据填报</div>
              </div>
            </div>
          </div>
          <router-link class="handle-right-item" to="/register/doctor">
            <div class="center-float-left">
              <img src="../assets/img/func-icon04.png" width="42" />
              <div class="pl-20">
                <div class="f24 t-cu pb-5 t-777">专家认证</div>
                <div class="center-float-left t-999"><i class="mr-10 el-icon-office-building"></i>专家认证</div>
              </div>
            </div>
          </router-link>
          <router-link class="handle-right-item" to="/register/pectoralgia">
            <div class="center-float-left">
              <img src="../assets/img/func-icon05.png" width="42" />
              <div class="pl-20">
                <div class="f24 t-cu pb-5 t-777">胸痛指导</div>
                <div class="center-float-left t-999"><i class="mr-10 el-icon-office-building"></i>胸痛指导</div>
              </div>
            </div>
          </router-link>
          <div class="handle-right-item" style="cursor: pointer" @click="eduAction()">
            <div class="center-float-left">
              <img src="../assets/img/func-icon06.png" width="42" />
              <div class="pl-20">
                <div class="f24 t-cu pb-5 t-777">培训学习</div>
                <div class="center-float-left t-999"><i class="mr-10 el-icon-office-building"></i>培训学习</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <router-link class="handle-right-next" to="/"><img src="../assets/img/home-next.png" /></router-link> -->
      </div>
      <div class="handle-right flex-1 center-float-left" style="position: relative;" v-else>
        <div style="position: absolute;right: 20px;top: 7px;"><a  @click="gotoVideo">查看更多</a></div>
        <div class="flex-1 left-float-top-br border-right" style="padding-top:25px;display: flex;flex-wrap: wrap;justify-content: flex-start;">

          <div class="handle-right-video-item "  v-for="(item,index) in projects" style="cursor: pointer" @click="gotoVideoDetail(item.id)">
            <div class="center-float-left" :style="'background-image: url('+ projects[index].coverPath.url+');background-size:100% 100%;height: 240px;'">

            </div>
            <div class="pl-10">
              <div class="f18 t-cu pb-5 t-777 ellipsis-multiline" style="font-size: 14px;">
                <el-row>
                  <el-col :span="20">
                    <div style="width: 100%;"><span class="text-nowrap">{{ item.name }}</span></div>
                  </el-col>
                  <el-col :span="4">
                   <span>{{item.leader}}</span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="home-title"><span class="f18 t-cu">主题服务<br/><i class="f12 t-999">SUBJECT SERVICE</i></span></div>
    <div class="center-float-left pt-30 pb-10">
      <div class="mr-20 service-item"><router-link to="/register/pectoralgia"><img src="../assets/img/serv-bg01.png" style="height: 255px;" /></router-link></div>
      <div class="mr-20 service-item"><router-link to="/"><img src="../assets/img/serv-bg02.png" style="height: 255px;" /></router-link></div>
      <div>
        <div class="mb-20 service-item"><router-link to="/"><img src="../assets/img/serv-bg03.png" style="width: 100%;" /></router-link></div>
        <div class="service-item"><router-link to="/"><img src="../assets/img/serv-bg04.png" style="width: 100%;" /></router-link></div>
      </div>
    </div>
    <!-- <img src="../assets/img/1.jpg" /> -->

  </div>


</template>

<script>
import { getStatisticsMonth } from '@/api/index'

const container = document.getElementsByClassName('scroll-container');
const content = document.getElementsByClassName('scroll-content');
export default{
  data() {
    return{
      currActive:'1',
      menuActive: '1',
      record: {},
      isDisplayVideo:false,
      isDisplayMessage:true,
      search:{
        keyword:'',
      },
      projects:[],
      message:[],
    }

  },
  mounted() {
    //this.loadMonthStatistics()

    let url=window.location.href
    var querys = url.substring(url.indexOf('?') + 1).split('&');
    var result=[];
    for(var i=0;i<querys.length;i++){
      var temp=querys[i].split('=');
      if(temp.length<2){
        result[temp[0]]='';
      }else{
        result[temp[0]]=temp[1];
      }
    }
    let organid=result['organid']
    if( null!= organid&&  undefined != organid && '' != organid ){
      this.$axios.post('/api/dmz/organization/register/'+organid+'/analog ')
          .then((res) => {
            if(null != res && null != res.msg && res.msg.flag === 0){
              localStorage.setItem('cpis-web_userInfo', JSON.stringify(res.biz))
            }else{
            }
          }).catch(() => {
      });
    }
    setInterval( this.getMessage(),5*60*100)
    this.loopScroll(container, content, 1000);
  },
  methods:{
    getMessage:function (){
      const self=this;
      this.$axios.get('/api/dmz/customer/approve/notice/list',{params:{status:'NEW'}})
          .then((res) => {
            if(res.msg.flag == 0){
              if(null == res.biz || res.biz.empty){
                self.$message.info({content: res.msg.message, duration: 2});
              }else{
                self.message=res.biz;
              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });
    },
    loadGrid:function(){
      const self = this;

      let params = Object.assign({'entity.keyword': this.search.keyword,'entity.status':'ENABLED'},
          {start: 0, length: 6});

      self.$axios.get('/api/admin/course/courses', {params: params}).then(function(res){
        if(res.msg.flag == 0){
          if(null == res.biz || res.biz.empty){
            self.projects=[];
          }else{
            self.projects=res.biz.data;
          }
        }else{
          console.info(res.msg.message);
        }
      },function(res){
        console.info(res);
      });
    },
    async loadMonthStatistics (val) {
      let self = this;
      let nowDate = new Date()
      let y = nowDate.getFullYear()
      let m = (nowDate.getMonth() + 1)
      let ym = y + (m < 10 ? '-0' + m : '-' + m)
      let yearTotals = 0;
      const res = await getStatisticsMonth({startDate:y+'-01-01'})
      for(let item of res.biz){
        months.push(item.month);
        datas.push(item.totals);
        yearTotals = yearTotals + item.totals;
        if(item.month == ym){
          self.$set(self.record, 'fillMongth', item.totals)
        }
      }
      self.$set(self.record, 'fillYear', yearTotals)
    },
    tbAction:function (){
      window.open("https://www.hunancpc.net/cpis-admin/");
    },
    boardAction:function (){
      window.open("https://www.hunancpc.net/cpis-admin/#/board");
    },
     gotoVideo:function (){
       this.$router.push('/video/index')
     },
    gotoVideoDetail:function (id){
      // let path= this.$router.resolve({ path: '/video/detail',query:{id:id}});
      let path= this.$router.resolve({ path: '/video/player',query:{id:id}});
          window.open(path.href, '_blank');
    },
    eduAction:function (){
      this.isDisplayVideo=true;
      this.loadGrid();
    },

    activeChange:function (_currActive){
      let self = this;
      this.currActive = _currActive;
      switch(_currActive){
        case '2': 
          this.$router.push('/auth/materials');
          break;
        case '3':
          self.tbAction();
          break;
        default:break
      }
      
    },

    // 循环滚动函数
     loopScroll:function(container, content, interval) {
        let scrollDistance = 0;
        setInterval(() => {
        scrollDistance += 1; // 每次滚动1px
        // content.style.transform = `translateY(-${scrollDistance}%)`;

          // 检查滚动是否到达底部，如果是，重置滚动距离
          if (scrollDistance >= 100) {
           scrollDistance = 0;
            // content.style.transform = `translateY(0)`;
           }
          }, interval);
      },


  }
}


</script>

<style lang="scss">
.home-menu{
  .el-menu-item{
    font-size: 16px !important;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    color: #555 !important;
    &.is-active, &.is-active a{
      color: #409EFF !important;
    }
  }
  a{
    font-weight: bold;
    color: #555 !important;
    text-decoration: none;
    display: block;
  }
}
.service-item{
  & img:hover{
    box-shadow: 0 0 8px 0px #38739f;
    border-radius: 8px;
  }
}
/* 模块名 */
.home-title{
  padding: 6px 0;
  border-bottom: 1px solid #ddd;
    line-height: 12px;
  span{
    position: relative;
    line-height: 18px;
    &::after{
      position: absolute;
      content: '';
      bottom: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background: #7978e2;
      border-radius: 2px;
    }
  }
  i{
    line-height: 12px;
  }
}

/* 功能操作 */
.handle{
  // margin-top: 20px;
  background: #fff;
  border-radius: 6px;
  .handle-left{
    width: 208px;
    border-right: 1px solid #eee;
    .handle-left-item{
      height: 54px;
      line-height: 34px;
      padding: 10px 10px 10px 30px;
      cursor: pointer;
      &:hover{
        background: #ecf3fd;
        color: #333;
      }
      &.active{
        background: #38739f;
        color: #fff;
      }
    }
  }
  .handle-right{
    .handle-right-item{
      background: #ecf3fd;
      margin: 10px 2%;
      padding: 30px 30px 30px 40px;
      width: 29%;
      text-decoration: unset;
      color: #444;
      &:hover{
        box-shadow: 0 0 8px 0px #38739f;
      }
    }
    .handle-right-video-item{
      background: #FEFEFE;
      margin-left: 3px;
      //margin: 10px 2%;
      padding: 3px 10px;
      width: 33%;
      box-sizing: border-box;
      text-decoration: unset;
      color: #444;
      &:hover{
        box-shadow: 0 0 8px 0px #38739f;
      }
    }
    .handle-right-next{
      width: 130px;
      text-align: center;
    }
  }
}

/* 统计 */
.statistics{
  .bg-white{
    border-radius: 6px;
  }
  &>div:hover .bg-white{
    // background: #f9f9f9;
    box-shadow: 0 0 8px 0px #38739f;
  }
  .statistics-item{
    padding: 10px 10px 20px 10px;
    margin: 40px 0 20px 0;
    .i{
      height: 60px;
      width: 60px;
      background: #e8e6fc;
      color: #7978e2;
      border-radius: 50%;
      padding: 10px;
      margin: 10px;
    }
    &:first-child{
      border-right: 1px solid #eee;
    }
  }
}


.ellipsis-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-card {
  width: 390px;
}
.el-alert--success.is-light {
  background-color:#43729E;
  color: #FFFFFF;
}
.fixed-div {
  position: fixed;
  top: 410px;
  right: 10px;
  width: 320px;
  height: 200px;
  background-color: #fff;
  //color: white;
  z-index: 1000; /* 确保元素在其他内容之上 */
  overflow: hidden;
  box-shadow: 0 0 9px 1px #ddd;
  border-radius: 4px;
  padding: 10px;
  padding-top: 56px;
}

@keyframes scroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.scroll-container {
  width: 300px;
  height: 160px;
  //overflow: hidden;
  position: relative;
}
.scroll-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
.scroll-header{
  height: 36px;
  line-height: 36px;
  padding-left: 0px;
  background-color: #e6a23c;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
  padding-left: 10px;
  border-bottom: 1px solid #f0f0f0;
}
.scroll-item{
  border-bottom: 1px dashed #ddd;
}
.slide {
  height: 100%;
  background-color: #ddd;
  line-height: 100px;
  text-align: center;
  font-size: 24px;
}
.text-nowrap{
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

}
</style>